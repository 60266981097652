<template>
    <div class="col-left">
        <div class="left-menu"></div>
        <div class="left-menu">
            <dl v-for="(item, idx) in menus" :key="idx">
                <dt>
                    <template v-if="item.path">
                        <a :href="item.path" :class="item.path == path ? 'router-link-active' : ''" @click="changeTPath(idx)">{{item.name}}</a>
                    </template>
                    <template v-else>{{item.name}}</template>
                </dt>
                <dd v-for="(child, cIdx) in item.children" :key="cIdx">
                    <a :href="child.path"  :class="child.path == path ? 'router-link-active' : ''" @click="changePath(idx, cIdx)">{{child.name}}</a>
                </dd>
            </dl>
        </div>
    </div>
</template>

<script>
    export default {
        name: "buyer-menu",
        data(){
            return {
                menus:[
                    {
                        name:"首页",
                        path:'/#/buyer/index',
                        active:false,
                    },
                    {
                        name:"订单中心",
                        path:'',
                        active:false,
                        children:[
                            {name:"订单管理", path:'/#/buyer/order-list', active:false},
                            {name:"快速下单", path:"/#/buyer/quick-order", active:false},
                            {name:"退换/售后", path:"/#/buyer/refund-list", active:false},
                        ]
                    },
                    {
                        name:"商品中心",
                        path:'',
                        active:false,
                        children:[
                            // {name:"商品变价记录", path:'/#/buyer/goods-offer', active:false},
                            {name:"我的选品库", path:"/#/buyer/my-choose", active:false},
                            {name:"我的商品库", path:"/#/buyer/my-goods", active:false},
                        ]
                    },
                    {
                        name:"银行账户",
                        path:'',
                        active:false,
                        children:[
                            {name:"银行帐户", path:'/#/buyer/property-bank', active:false},
                            {name:"发票管理", path:'/#/buyer/property-invoice', active:false},
                            {name:"预付款", path:'/#/buyer/property-prepay', active:false},
                        ]
                    },
                    {
                        name:"数据中心",
                        path:'',
                        active:false,
                        children:[
                            {name:"数据看板", path:'/#/buyer/board', active:false},
                            {name:"实时概览", path:'/#/buyer/situation', active:false},
                        ]
                    },
                    {
                        name:"设置中心",
                        path:'',
                        active:false,
                        children:[
                            {name:"安全中心", path:'/#/buyer/setting-safety', active:false},
                            {name:"企业档案", path:'/#/buyer/setting-profile', active:false},
                            {name:"发票抬头", path:'/#/buyer/setting-invoice', active:false},
                            {name:"消息通知", path:'/#/buyer/setting-msg', active:false},
                        ]
                    }
                ],
                path:''

            }
        },
        created(){
            this.path = '/#'+this.$route.path;
        },
        methods:{
            changeTPath(idx){
                this.path = this.menus[idx].path;
            },
            changePath(idx, cIdx){
                this.path = this.menus[idx].children[cIdx].path;
            }
        }
    }
</script>

<style lang="scss" scoped>

    dd {
        margin-bottom: 0.5em;
        margin-left: 0;
    }

    .col-left {
        width: 180px;
        background-color: #fff;
        min-height: 800px;

        .left-menu {
            padding: 10px 0 0 40px;
            font-family: PingFangSC-Medium, PingFang SC;

            a {
                color: #333;
            }

            dl {
                margin-bottom: 10px;

                dt {
                    line-height: 40px;
                    color: #333;
                    font-size: 16px;
                    font-weight: 700;
                }

                dd {
                    font-size: 12px;
                    line-height: 24px;
                    color: #333;
                }
            }
        }
    }
</style>