<template>
      <div class = "buyer">
            <cHeaderBuyer activeNav="buyer"></cHeaderBuyer>
            <div class = "body">
                  <div class = "page-wrap">
                        <cBuyerMenu></cBuyerMenu>
                        <router-view></router-view>
                  </div>
            </div>
            <cFooter></cFooter>
      </div>
</template>

<script>
      import cHeaderBuyer from '../component/header-buyer';
      import cFooter from '../component/footer';
      import cBuyerMenu from '../component/buyer-menu';

      export default {
            name: "order-list",
            components: {
                  cHeaderBuyer,
                  cFooter,
                  cBuyerMenu
            },
      }
</script>

<style lang="scss" scoped>
      .body {
            background: #fbfbfb;
      }

      .page-wrap {
            width: 1200px;
            margin: 0 auto;
      }
      a {
            color: #de0000;
            text-decoration: none;
            background-color: transparent;
            outline: none;
            cursor: pointer;
            transition: color .3s;
            -webkit-text-decoration-skip: objects;
      }

      dt {
            font-weight: 500;
      }

      dd {
            margin-bottom: 0.5em;
            margin-left: 0;
      }

      .body {
            background: #fbfbfb;

            .page-wrap {
                  width: 1200px;
                  margin: 0 auto;
                  display: flex;
            }
      }
</style>