<template>
    <div class="header">
        <cTop></cTop>
        <div class="warp-search">
            <div class="header-search">
                <div class="search-logo" @click="$jumpTo('/')"><img src="../assets/img/logo.png" alt=""></div>
                <div name="nav" class="header-nav">
                    <a href="/#/buyer/index" :class="activeNav == 'buyer' ? 'on' : ''">买家中心</a>
                    <a href="/#/atc/account" :class="activeNav == 'atc' ? 'on' : ''">账户中心</a>
                </div>
                <div class="search-box">
                    <div class="d-flex">
                        <div class="left">
                            <input v-model="searchKeyword" type="text" placeholder="商品名称/商品编号">
                        </div>
                        <div class="right">
                            <button class="ant-btn"  @click="search">
                                <span>搜索</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="search-goods">
                    <a href="/#/buyer/my-goods" class="goods-box">
                        <img src="../assets/img/myGoods.png" alt="">
                        <strong>我的商品库</strong>
                        <span class="badge">{{ myLibCount }}</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import cTop from "@/component/top";
    import service from "../../utils/request";

    export default {
        name: "cHeader",
        components: {
            cTop
        },
        props: {
            activeNav: {
                type: String,
                default: 'buyer'
            },
        },
        data() {
            return {
                headerWarpActive: '',
                buyerCenterActive: '',
                myLibCount:0,
                searchKeyword:''
            }
        },
        created() {
            this.getMyLibCount();
        },
        methods: {

            headerWarpMouseOver() {
                console.log('over');
                this.headerWarpActive = 'selected'
            },
            headerWarpMouseLeave() {
                this.headerWarpActive = ''
            },
            buyerCenterMouseOver() {
                this.buyerCenterActive = 'selected'
            },
            buyerCenterMouseLeave() {
                this.buyerCenterActive = ''
            },

            getMyLibCount(){
                service.post(service.uri.goods.getMyLibCount).then(res => {
                    if(res.code == 200){
                        this.myLibCount = res.data
                    }
                });
            },

            keyword(nv){
                this.searchKeyword = nv;
            },
            search(){
                this.$router.push({name: 'Home', params: {keyword: this.searchKeyword}});
                return;
            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "../assets/css/common";

    .header {
        overflow: hidden;
        min-width: 1200px;

        .header-nav {
            display: flex;
            flex: 1;
            align-items: center;
            margin-left: 30px;

            a {
                display: block;
                margin: 0 25px;
                position: relative;
                height: 40px;
                font-size: 16px;
                line-height: 38px;
                font-family: PingFangSC-Regular, PingFang SC;
                color: #333;
            }

            a.on {
                color: $defaultColor;
            }

            a.on:before {
                display: block;
                content: "";
                width: 24px;
                height: 2px;
                border-radius: 1px;
                background-color: #de0000;
                position: absolute;
                left: 50%;
                bottom: 0;
                margin-left: -12px;
            }
        }

        .warp {
            background: #f0f0f0;
            font-size: 12px;
            color: #666;
            height: 38px;
            line-height: 38px;
            margin: auto;

            .user-warp {

                width: 1200px;
                margin: auto;
            }

            .nickname {
                float: left;

                .nickname-show {
                    padding: 0 20px;
                }
            }

            .company-name {
                float: left;

                .company-name-show {
                    padding: 0 20px;
                }
            }

            .header-nav {
                float: right;
                /*min-width: 300px;*/
                .header-nav-item {
                    list-style: none;
                    float: left;
                    width: 80px;
                    text-align: center;

                    .header-nav-sub {
                        position: absolute;
                        z-index: 2;
                        width: 80px;
                        box-shadow: 2px 2px 10px #f0f0f0;

                        background-color: #fff;

                        li {
                            list-style: none;
                        }
                    }
                }
            }

            .arrow_b {
                position: relative;
                top: -2px;
            }

            .arrow_t {
                transform: rotate(180deg);
            }

            .selected {
                background: #fff;
            }

            .badge {
                background-color: $defaultColor;
                color: #fff;
                padding: 0 5px;
                border-radius: 10px;
            }

            .selected .user-info, .selected .buyer-center-op {
                display: block;
            }

            .user-info, .buyer-center-op {
                display: none;
            }

            .user-info {
                position: absolute;
                z-index: 2;
                padding: 15px;
                box-shadow: 2px 2px 10px #f0f0f0;
                background-color: #fff;

                .info {
                    overflow: hidden;
                }

                .avatar {
                    width: 30px;
                    height: 30px;
                    float: left;
                }

                .user-account-info {
                    margin: 0 15px;
                    float: left;
                    line-height: 18px;
                }

                .logout {
                    float: left;
                    line-height: 36px;
                    border-left: 1px solid #f0f0f0;
                    padding-left: 10px;
                    color: $defaultColor;
                }

                .account {
                    overflow: hidden;
                    margin-top: 15px;
                    line-height: 20px;
                    display: flex;
                    justify-content: space-between;

                    .account-setting, .my-company {
                        float: left;
                    }
                }
            }
        }

        .warp-search {
            position: relative;
            z-index: 1;
            background: #fff;
            box-shadow: inset -1px 0px 5px -3px rgb(0 0 0 / 30%);


            .header-search {
                width: 1200px;
                margin: auto;
                overflow: hidden;
                display: flex;
                cursor: pointer;

                .search-logo, .search-box {
                    height: 105px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .search-box {
                    font-size: 14px;
                    width: 300px;
                    margin-left: 116px;

                    .d-flex {
                        flex: 1;
                        height: 40px;
                        border-radius: 25px;
                        background: #f4f4f4;
                        display: flex;
                        justify-content: space-between;
                    }

                    .left {
                        height: 40px;
                        display: flex;
                        align-items: center;
                        flex: 1;
                        padding: 0 25px;

                        input {
                            width: 100%;
                            border: none;
                            background-color: #f4f4f4;
                            outline: none;
                        }
                    }

                    .right {
                        width: 79px;
                        text-align: center;
                        color: #fff;

                        .ant-btn {
                            width: 79px;
                            height: 40px;
                            background: $defaultColor;
                            border: 1px solid transparent;
                            border-top-right-radius: 25px;
                            border-bottom-right-radius: 25px;

                        }

                        span {
                            font-size: 14px;
                            font-weight: 400;
                            color: #fff;
                        }
                    }
                }

                .search-goods {
                    height: 105px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    font-size: 14px;
                    margin-left: 51px;

                    .goods-box {
                        min-width: 178px;
                        height: 40px;
                        border-radius: 25px;
                        border: 1px solid #ededed;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        strong {
                            margin: 0 4px 0 6px;
                            color: #323238;
                            font-style: normal;
                            font-weight: 400;
                        }

                        span {
                            padding: 0 8px;
                            height: 16px;
                            line-height: 16px;
                            border-radius: 8px;
                            background: #323238;
                            font-size: 12px;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }

</style>